import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SubscribeForm from "../../components/subscribeForm";
import { Button, HeaderPlaceholder } from "../../styles/ui.styles";
import { FaCheck } from "react-icons/fa";

const Leisure: React.FC = () => {
  return (
    <>
      <SEO title={"Leisure overview"} description={"Leisure overview"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <h1>Leisure</h1>
          <p>
            The Leisure Yacht Edition onboard unit transforms sailing sessions
            to memorable nautical adventures, while integrating well with
            existing ship instruments. Through having an instantly accessible
            view of the boat motion data, weather conditions and data analytics-based
            insights Fastrrr improves sailing skills and allows the captain
            to be the fastest on the waves.
          </p>

          <WhatsMoreGrid>
            <div>
              <ul>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Accessibility
                  </StyledListElement>
                  <p>
                    We offer a comprehensive yet straightforward data analytics interface, which delivers insights for all sailor levels.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Compatibility
                  </StyledListElement>
                  <p>NMEA2000 and NMEA0183 compatible.</p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Achievements
                  </StyledListElement>
                  <p>Complete thrilling tasks by yourself or with fellow sailors to improve your sailing skills.</p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Captain’s logbook
                  </StyledListElement>
                  <p>Enjoy the sailing, we do all the paperwork.</p>
                </li>
              </ul>
            </div>
          </WhatsMoreGrid>

          <br />

          <LeisureProductsWrapper>
            <LeisureProductCard>
              <h4>DinghyEdition</h4>
              <StaticImage
                style={{ width: "100%", height: "200px" }}
                src={"../../images/dinghy_edition.jpg"}
                alt={"DinghyEdition"}
                objectFit={"contain"}
              />
              <Link to={"/products/dinghy-edition"}>
                <Button primary={true}>SEE MORE</Button>
              </Link>
            </LeisureProductCard>
            <LeisureProductCard>
              <h4>YachtEdition</h4>
              <StaticImage
                style={{ width: "100%", height: "200px" }}
                src={"../../images/yacht_edition.jpg"}
                alt={"YachtEdition"}
                objectFit={"contain"}
              />

              <Link to={"/products/yacht-edition"}>
                <Button primary={true}>SEE MORE</Button>
              </Link>
            </LeisureProductCard>
          </LeisureProductsWrapper>
        </Wrapper>
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default Leisure;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;
  ol ol li {
    list-style-type: lower-latin;
  }
`;

const LeisureProductsWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const LeisureProductCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 22px;
  box-shadow: var(--shadow);
  a {
    margin: 30px 0;
    text-decoration: none;
  }

  @media (max-width: 480px) {
    a,
    button {
      width: 100%;
    }
  }
`;

const WhatsMoreGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  div {
    padding: 32px;
  }

  div:nth-child(2),
  div:nth-child(3) {
    background: #f3f3f3;

    p {
      margin-left: 32px;
    }
  }

  li {
    text-decoration: none;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-orange);
      margin-right: 16px;
    }
  }

  strong {
    color: #000;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    div:nth-child(3) {
      order: 4;
    }
    div:nth-child(4) {
      order: 3;
    }
  }
`;

const StyledListElement = styled.strong`
  display: flex;
  align-items: center;
`;
